import React, { useContext } from 'react'
import parse from 'html-react-parser'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import './styles.scss'

const Callout = loadable(() => import('/src/components/Cards/Callout'))
const SocialLinks = loadable(() => import('/src/components/Basic/SocialLinks'))
const UnorderedList = loadable(() => import('/src/components/Lists/UnorderedList'))

const ContactSidebar = () => {
    const [options] = useContext(OptionsContext)

    const address = options.companyDetails.address
    const telephoneNumber = options.companyDetails.telephoneNumber
    const officeHours = options.companyDetails.officeHours

    return (
        <Callout className={'c-contact-sidebar'}>
            <address className="c-contact-sidebar__details">
                <div className="c-contact-sidebar__address">{parse(address)}</div>
                <div className="c-contact-sidebar__tel">
                    Tel: <a href={`tel: ${telephoneNumber}`}>{telephoneNumber}</a>
                </div>
            </address>

            {officeHours && (
                <div className="c-contact-sidebar__office-hours">
                    <h4 className="c-contact-sidebar__office-hours-title">Office hours</h4>

                    <UnorderedList className={'c-contact-sidebar__office-hours-list'}>
                        {officeHours.map((node, index) => (
                            <li key={index}>
                                <span className="c-contact-sidebar__office-hours-day">{node.dayRange}:</span>
                                <span className="c-contact-sidebar__office-hours-time">{node.times}</span>
                            </li>
                        ))}
                    </UnorderedList>
                </div>
            )}

            <SocialLinks />
        </Callout>
    )
}

export default ContactSidebar
